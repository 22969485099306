// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-en-js": () => import("./../../../src/pages/about-us.en.js" /* webpackChunkName: "component---src-pages-about-us-en-js" */),
  "component---src-pages-ctrm-en-js": () => import("./../../../src/pages/ctrm.en.js" /* webpackChunkName: "component---src-pages-ctrm-en-js" */),
  "component---src-pages-ctrm-js": () => import("./../../../src/pages/ctrm.js" /* webpackChunkName: "component---src-pages-ctrm-js" */),
  "component---src-pages-form-js": () => import("./../../../src/pages/form.js" /* webpackChunkName: "component---src-pages-form-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-om-oss-js": () => import("./../../../src/pages/om-oss.js" /* webpackChunkName: "component---src-pages-om-oss-js" */),
  "component---src-pages-rapporter-js": () => import("./../../../src/pages/rapporter.js" /* webpackChunkName: "component---src-pages-rapporter-js" */),
  "component---src-pages-reports-en-js": () => import("./../../../src/pages/reports.en.js" /* webpackChunkName: "component---src-pages-reports-en-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */)
}

